<template>
  <div class="p-4 m-auto flex flex-col ">
    <div class="flex h-screen flex-col items-center justify-between">
      <div class="w-full z-0 fixed inset-0">
        <img
            alt="Background"
            src="./images/bg.jpg"
            class="w-full h-full object-cover"
        />
      </div>
      <div class="relative z-99 mx-4 w-full md:w-3/4 lg:w-1/2 mt-24 md:mt-12">
        <div
            class="mt-24 h-full  w-full bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-gray-100 p-4">
          <ul class="text-white">
            <li class="flex items-center py-2 md:py-4">
              <span class="mr-2 text-2xl">&#9679;</span>
              <span class="text-lg md:text-xl lg:text-lg flex-1">$2K - A COMMUNITY TOKEN DESIGNED TO COMMEMORATE ETHEREUM'S RESURGENCE TO $2,000</span>
            </li>
            <li class="flex items-center py-2 md:py-4">
              <span class="mr-2 text-2xl">&#9679;</span>
              <span class="text-lg md:text-xl lg:text-lg flex-1">$2K SYMBOLIZES RESILIENCE AND DEDICATION OF THE ETHEREUM AND CRYPTO COMMUNITY.</span>
            </li>
            <li class="flex items-center py-2 md:py-4">
              <span class="mr-2 text-2xl">&#9679;</span>
              <span class="text-lg md:text-xl lg:text-lg flex-1">WITH EVERY TRANSACTION, HOLDERS OF $2K ARE REWARDED WITH WRAPPED ETHEREUM, CREATING AN ECOSYSTEM THAT ACKNOWLEDGES THE PAST AND ALSO INVESTS IN THE FUTURE.</span>
            </li>
          </ul>
          <div class="z-99 w-full md:p-14 flex items-center justify-center">
            <h1 class="cursor-pointer text-white text-2xl md:text-5xl lg:text-4xl font-bold text-center md:smaller lg:smaller"
                @click="copyContractAddress">contract: 0x00000000000000</h1>
          </div>
        </div>
      </div>
      <div class="relative z-99 mx-4 w-1/2 h-1/2 md:h-1/4 lg:h-1/2 mt-20 md:mt-12 transition-all ease-out duration-500">
        <img
            alt="Head"
            src="./images/logo2.png"
            class="inset-0 z-99 box-border p-0 m-auto block w-0 h-0 min-w-full max-w-full min-h-full max-h-full object-contain"
        />
      </div>
    </div>


    <button @click="congratulationsAnimation" class="animate__animated left">
      <h1 class="big bg-white p-2 hover:bg-yellow-200">WAGMI!</h1>
    </button>
    <PopupVue ref="confirmationPopup" class="left1">
      <template #actions="{ confirm }" class="popuptem">
        <h1 class="popuptem font-semibold">
          Welcome to $2K, a community token designed to commemorate Ethereum's
          resurgence to $2,000 after nearly a year and a half of anticipation.
        </h1>
        <h1 class="popuptem font-semibold">
          $2K is more than just a digital asset, it symbolizes unwavering
          resilience and dedication of the Ethereum and crypto community.
        </h1>
        <h1 class="popuptem font-semibold">
          With every transaction, holders of $2K are rewarded with wrapped
          Ethereum, creating an ecosystem that not only acknowledges the past
          but also invests in the future.
        </h1>
        <h1 class="popuptem text-yellow-900 font-semibold">
          Join the celebration and be a part of this exciting journey as
          Ethereum continues to shape the future of blockchain technology.
        </h1>
        <div>
          <div>
            <p>CA: TBD</p>
          </div>
          <div>
            <p>Supply: 2,000</p>
          </div>
          <div>
            <p>Tax: 3% WETH rewards</p>
          </div>
        </div>
        &nbsp;
        <button
            @click="confirm"
            :disabled="isConfirmationCorrect"
            class="bg-purple-700 rounded-sm text-white p-2"
        >
          OK
        </button>
      </template>
    </PopupVue>
    <div class="fixed bottom-0 left-0 right-0 flex justify-center">
      <div class="fixed bottom-0 left-0 right-0 flex justify-center">

      </div>
    </div>
    <div class="fixed right-4 bottom-4 ">
      <a href="https://twitter.com/2000sEthCoin">
        <img src="./images/follow.png" class="bg-white" alt=""/>
      </a>
    </div>

  </div>
</template>

<script>
import Web3 from "web3"
import {mapGetters} from "vuex"
import PopupVue from "./components/Popup.vue"
import confetti from 'canvas-confetti';
import GMAudio from './gm.mp3';

export default {
  components: {PopupVue},
  name: "Header",

  data() {
    return {
      confirmation: "",
    }
  },

  computed: {
    ...mapGetters("wallet", [
      "getWeb3",
      "getUserAccount",
      "getInstance",
      "getContractABI",
      "getContractAddress",
    ]),

    isConfirmationCorrect() {
      return this.confirmation === this.$options.CONFIRMATION_TEXT
    }
  },

  methods: {

    congratulationsAnimation() {
      const audio = new Audio(GMAudio);
      audio.play();

      confetti({
        particleCount: 100,
        spread: 70,
        origin: {y: 0.6},
        // Other options as needed
      });


      // Add animation class to the button
      const el = document.querySelector('button.animate__animated');
      el.classList.add('animate__heartBeat');

      // Remove animation class after a duration
      setTimeout(() => {
        el.classList.remove('animate__heartBeat');
      }, 2000);
    },

    copyContractAddress() {
      const contractAddress = '0x00000000000000'; // Replace this with your actual contract address

      // Create a temporary textarea to copy the text to the clipboard
      const textarea = document.createElement('textarea');
      textarea.value = contractAddress;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px'; // Move the textarea off-screen
      document.body.appendChild(textarea);

      // Select and copy the text
      textarea.select();
      document.execCommand('copy');

      // Clean up: remove the textarea
      document.body.removeChild(textarea);

      // Optionally, show a message or perform other actions after copying
      alert('Contract address copied to clipboard!');
    },

    async onConnect() {
      try {
        let provider = await this.getWeb3Modal().connect();
        this.onProvider(provider);
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }
    },

    async onProvider(provider) {
      try {
        let web3 = new Web3(provider);
        let chain = await web3.eth.getChainId();
        console.log(chain);

        if (this.chainId != chain) {
          this.$toasted.show("Connect your wallet to the Ethereum network");
          return;
        }

        this.SET_WEB3(web3);

        let accounts = await web3.eth.getAccounts();
        this.SET_USER_ACCOUNT(accounts[0]);

        let instance = new this.getWeb3.eth.Contract(
            this.getContractABI,
            this.getContractAddress
        );

        this.SET_INSTANCE(instance);
        this.readValue();
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }

      provider.on("accountsChanged", (accounts) => {
        this.SET_USER_ACCOUNT(accounts[0]);
      });
    },

    async learnVue() {
      this.confirmation = "";

      const popupResult = await this.$refs.confirmationPopup.open();

      if (popupResult) {
        alert("WAGMI!");
      }
    },

    addrTruncation(addr) {
      return addr.slice(0, 6) + "…" + addr.slice(addr.length - 4, addr.length);
    },

    disconnect() {
      this.SET_WEB3(null);
      this.SET_INSTANCE(null);
      this.SET_USER_ACCOUNT(null);
    },
  },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}

.hide {
  display: none;
}

.big {
  font-size: 12px;
  font-weight: 900;
  /* background-color: #aebfff07; */
  border-radius: 10px;
  text-decoration: none;
  color: #000;
  width: 160px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
}

.popuptem {
  text-align: justify;
  padding: 14px;
}

.left {
  position: fixed;
  left: 14px;
  bottom: 14px;
}

.free {
  font-size: 24px;
  font-style: oblique;
}

body {
  width: auto;
  height: 100vh;
  font-family: Sans-Serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0px;
  width: 100%;
}

.button11 {
  position: absolute;
  top: 10px;
  right: 10px;
}

.button {
  border-radius: 10px;
  padding: 20px 15px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  font-size: 28px;
  width: 160px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.button:hover {
  border-color: #31ffc1;
  color: #31ffc1;
}

#mint-button:hover {
  border-color: #ff0000;
  color: #ff0000;
}

.inputmint {
  border: 6px solid #000;
  border-radius: 10px;
  padding: 20px 15px;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  color: #000;
  text-transform: lowercase;
  font-size: 28px;
  width: 220px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.button-info .price {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.primary .container .main img {
  width: 100%;
}

.side {
  @apply flex flex-col items-center justify-between h-screen;
}

@keyframes bgcolorchange {
  0% {
    background-color: #000000;
  }

  100% {
    background-color: #000000;
  }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes bgcolorchange {
  0% {
    background: red;
  }

  25% {
    background: yellow;
  }

  75% {
    background: green;
  }

  100% {
    background: blue;
  }
}

@media screen and (max-width: 980px) {
  #logo {
    grid-column: 1 / span 2;
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding: 0 30px;
  }

  .left1 {
    height: 100vh;
  }

  .big {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    left: 10%;
    padding: 0;
    margin: 0;
    bottom: 10px;
    width: 110%;
    background-color: #aec0ff;
  }

  .button {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    right: 55%;
    top: 14px;
    width: 110%;
    background-color: #aec0ff;
    padding: 0;
  }

  .button12 {
    font-size: 20px;
    font-weight: bold;
    position: relative;

    width: 100%;
    background-color: #aec0ff;
    padding: 0;
  }

  .popuptem {
    height: 50vh;
    font-size: 12px;
    padding: 4px;
    text-align: justify;
  }

  .popuptem.h1 {
    text-align: justify;
  }

  .header .container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  #logo {
    grid-column: 1;
    grid-row: 2;
  }

  #connect-wallet {
    grid-column: 1;
    grid-row: 1;
  }

  .primary {
    margin-top: 20px;
  }

  .primary .container {
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .primary .container .side {
    gap: 30px;
    align-items: top;
    margin-bottom: 30px;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .primary .container .main {
    grid-column: 1;
  }

  .primary .container .bottom {
    grid-column: 1;
    grid-row: 2;
  }

  .bottom {
    padding: 0;
    margin-bottom: 10px;
  }
}

#alert-bar {
  -webkit-box-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 2px solid #000;
  color: #000000;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px 0;
  font-weight: 500;
  min-height: 48px;
  height: auto;
  position: absolute;
  width: 100%;
  top: 0px;
  opacity: 0;
  transition: all 0.3s ease 0s;
  z-index: 10000;
  font-weight: 700;
  line-height: 47px;
  font-size: 18px;
  letter-spacing: 0.5px;
}

#alert-bar a {
  color: #3160ff;
  display: inline-block;
}

#alert-bar .alert-content {
  margin-right: 20px;
  line-height: 1.5em;
}

#close-alert-bar {
  width: 15px;
}

#close-alert-bar img {
  width: 100%;
}

.alert-content a {
  text-decoration: underline;
}

@media (max-width: 680px) {
  #alert-bar {
    position: relative;
    display: none;
    height: auto;
  }

  .alert-content {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.5em;
  }

  #close-alert-bar {
    position: absolute;
    right: 10px;
  }
}

.footer {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footer a {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  letter-spacing: 1px;
}

.footer a:hover {
  color: #3160ff;
}
</style>
